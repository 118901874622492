body {
  margin: 0;
  font-family: Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.2;
  background: #222;
}

#root {
  min-height: 100%;
}

.ui.radio.checkbox label {
  color: #fff !important;
  font-size: 18px;
}
